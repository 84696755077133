/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'center': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g _fill="#FFF" fill-rule="nonzero"><path pid="0" d="M3.276 8.802c1.583.001 2.952.321 4.096.968l.26.156-.826 1.313c-.717-.45-1.575-.731-2.583-.837l-.137-.013.014.151c.14 1.328.588 2.398 1.339 3.232l.179.188c1.043 1.044 2.487 1.569 4.381 1.566 1.899 0 3.344-.526 4.383-1.566.856-.856 1.364-1.987 1.517-3.42l.013-.152-.201.02c-.86.096-1.609.322-2.254.676l-.237.138-.82-1.318c1.106-.687 2.432-1.05 3.967-1.097l.358-.005.775.001v.775c-.001 2.288-.671 4.13-2.02 5.48-1.35 1.349-3.192 2.019-5.479 2.02-2.284.003-4.127-.667-5.48-2.02-1.283-1.282-1.952-3.004-2.016-5.124L2.5 9.577l.001-.775h.775z" fill-opacity=".75"/><path pid="1" d="M10.548 3.547L10.001 3l-.548.545C7.457 5.531 6.438 7.71 6.438 10.051c0 2.342 1.019 4.52 3.015 6.505l.548.546.547-.547c1.995-1.992 3.013-4.169 3.013-6.504s-1.018-4.512-3.013-6.504zm-.55 1.681l.168.194c1.238 1.48 1.844 3.017 1.844 4.629l-.007.284c-.067 1.509-.672 2.952-1.837 4.345l-.167.192-.166-.19C8.596 13.206 7.99 11.667 7.99 10.05c0-1.617.606-3.155 1.843-4.63l.166-.193z"/></g>'
  }
})
