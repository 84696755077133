import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import UserStorage from '@/storages/user'
import { IPermissionData } from '@/api/types'
import SystemApi from '@/api/system'
import WecomApi from '@/api/wecom'
import ArrayUtil from '@/utils/array'
import PermissionConst from '@/constant/permissions'
import { ConfigApi } from '@/proxy'

/* eslint-disable camelcase */
interface IOauthData {
  accessToken: string
  refreshToken: string
  expireAt: number
}

export interface IUserState {
  oauth: IOauthData
  avatar: string
  abpUserId: string
  permissions: IPermissionData[]
}

@Module({
  dynamic: true,
  store,
  name: 'user'
})
class User extends VuexModule implements IUserState {
  public oauth = {
    accessToken: UserStorage.accessToken,
    refreshToken: UserStorage.refreshToken,
    expireAt: UserStorage.expireAt
  }

  public avatar = 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif'
  public permissions: IPermissionData[] = []
  public abpUserId = ''
  public username = ''

  @Mutation
  private SET_ACCESS_TOKEN(oauth: IOauthData) {
    this.oauth = oauth
  }

  @Mutation
  private SET_ABP_USER_ID(abpUserId: string) {
    this.abpUserId = abpUserId
  }

  @Mutation
  private SET_ABP_USER_NAME(username: string) {
    this.username = username
  }

  @Mutation
  private SET_AVATAR(avatar: string) {
    this.avatar = avatar
  }

  @Mutation
  private SET_PERMISSIONS(permissions: IPermissionData[]) {
    this.permissions = permissions
  }

  @Action
  public DpLogin(oauth: IOauthData) {
    this.SET_ACCESS_TOKEN(oauth)
  }

  @Action
  public ResetToken() {
    this.SET_ACCESS_TOKEN({
      accessToken: '',
      refreshToken: '',
      expireAt: 0
    })
    this.SET_PERMISSIONS([])
    this.SET_ABP_USER_ID('')
  }

  @Action({ rawError: true })
  public async GetUserInfo() {
    const { data: abpUserData } = await WecomApi.corpUserInfo()
    if (!abpUserData?.abpUserId) {
      return
    }
    this.SET_ABP_USER_ID(abpUserData.abpUserId)
    this.SET_ABP_USER_NAME(abpUserData.name)
    const { data: permissionData } = await SystemApi.getUserPermission(abpUserData.abpUserId)
    const backendPermissions = ArrayUtil.filter<IPermissionData>(permissionData?.groups).find(
      permission => {
        return permission.name === PermissionConst.Group.DpBackend.groupName
      }
    )
    const abpPermissions = ArrayUtil.filter<IPermissionData>(permissionData?.groups).find(
      permission => {
        return permission.name === PermissionConst.Group.AbpIdentity.groupName
      }
    )
    if (!backendPermissions) {
      return
    }
    if (abpPermissions) {
      abpPermissions.permissions.forEach(element => {
        backendPermissions.permissions.push(element)
      })
    }
    const permissionDeleteList: any[] = []
    const { data } = await new ConfigApi().apiAppConfigGet()
    if (!data.Config_Key_WeCom) {
      PermissionConst.rejectPermissionsList.Config_Key_WeCom.forEach(element => {
        permissionDeleteList.push(element)
      })
    }
    if (!data.Config_Key_Oceanengine) {
      PermissionConst.rejectPermissionsList.Config_Key_Oceanengine.forEach(element => {
        permissionDeleteList.push(element)
      })
    }
    if (!data.Config_Key_WebCall) {
      PermissionConst.rejectPermissionsList.Config_Key_WebCall.forEach(element => {
        permissionDeleteList.push(element)
      })
    }
    // if (!data.Config_Key_WeChatApplet) {
    //   PermissionConst.rejectPermissionsList.Config_Key_WeChatApplet.forEach(element => {
    //     permissionDeleteList.push(element)
    //   })
    // }
    const backendPermissionsList: any[] = []
    backendPermissions.permissions.forEach(element => {
      if (permissionDeleteList.indexOf(element.name) === -1) {
        backendPermissionsList.push(element)
      }
    })
    this.SET_PERMISSIONS(backendPermissionsList)
    this.SET_AVATAR('https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif')
  }

  @Action
  public LogOut() {
    if (this.oauth.accessToken === '') {
      throw Error('LogOut: token is undefined!')
    }
    this.ResetToken()
  }
}

export const UserModule = getModule(User)
